import React from "react";
import SEO from '../components/seo';

const Contact = ({ data }) => (
	<div className="wrapper-main">
		<SEO title="Contact" />
		<iframe
			src="https://docs.google.com/forms/d/e/1FAIpQLScHxibLusb600VxHStzPrDa8BoNhdjkylJ_uPwth9RfMo1c8w/viewform?embedded=true"
			width="100%"
			height="1151"
			frameborder="0"
			marginheight="0"
			marginwidth="0"
		>
			Loading...
		</iframe>
	</div>
);

export default Contact;
